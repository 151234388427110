:root {
  --background-colour: #6b6e70;
}

.SharedOutter {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: 12vh;
	padding-bottom: 12vh;
	margin-bottom: 10px;
}

.SharedOutter2 {
	padding-top: 12vh;
	padding-bottom: 12vh;
	box-shadow: 0px 3px 5px var(--lower-shadow), 0px -3px 5px var(--lower-shadow);
	margin-bottom: 10px;
  	background-color: var(--lower-background);
}