.Experience-Outter {
  background-color: var(--background-colour);
}

.Experience-Title {
  display: flex;
  justify-content: center;
}

.Experience-Title > h1 {
  margin-bottom: 20px;
  font-size: 4.5rem;
  color: #A6CE28; 
}

.Experience-List {
  margin-left: 10vw;
  margin-right: 10vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.Experience-Item {
  display: flex;
  flex-direction: row;
  width: 22rem;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(35, 39, 42, 1);
  border-radius: 10px;
  margin: 20px;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: flex-start;
  align-items: center;
  height: 17rem;
}

.Experience-Arrow {
  flex: 0.05;
  width: 2rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.Experience-Arrow-Image {
  width: 1rem;
  height: 2rem;
}

.Experience-Arrow-Image-Down {
  transition: transform 0.6s;
  transform: rotateY(0deg);
}

.Experience-Arrow-Image-Up {
  transition: transform 0.6s;
  transform: rotateY(180deg);
}

.Experience-Item:hover {
  cursor: pointer;
  background-color: #6b6e70; 
  filter: brightness(0.8);
  border-radius: 10px;
}

.Experience-Item-Top {
}

.Experience-Item-Top-Open {
  display: flex;
  flex: 0.95;
  flex-direction: column;
  align-items: center;
}

.Experience-Item-Top-Closed {
  display: none;
  
}

.Experience-Item-Top > h1 {
  color: #A6CE28;
  font-size: 1.7rem;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

}

.Experience-Item-Top > h2 {
  color: #E7C855;
  font-size: 1.5rem;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.Experience-Item-Top > h3 {
  color: #dadfe1;
  margin-top: 5px;
  font-size: 0.8rem;
  text-align: center;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.Experience-Item-Top > img {
  margin-top: 10px;
  margin-bottom: 5px;
  width: 15rem;
}

.Experience-Item-Bottom {
  height: inherit; 
  margin-left: 20px;
}

.Experience-Item-Bottom > div {
  height: 90%;
  margin-top: 15px;
  margin-bottom: 15px;
  overflow-y: auto;
}

.Experience-Item-Bottom-Closed {
  display: none;
}

.Experience-Item-Bottom-Open {
  color: #dadfe1;
}

.Experience-Item-Bottom-Text {
  margin-bottom: 15px;
  margin-right: 25px;
}