.Portfolio-Item-Page-Outter {
	background-color: #474b4f;
	width: 100%;
	min-height: 100vh;
	padding-bottom: 100px;
}

.Portfolio-Item-Back {
	position: fixed;
	margin-top: 10px;
	margin-left: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: rgba(35, 39, 42, 0.75);
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	z-index: 999;
  	text-decoration: none;
}

.Portfolio-Item-Back > img {
	height: 50px;
	margin-left: 10px;
}

.Portfolio-Item-Back:hover { 
  	filter: brightness(0.8);
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

.Carousel {
	display: flex;
	height: 40vh;
	margin-left: 20vw;
	margin-right: 20vw;
	margin-bottom: 50px;
}

.Carousel-Mobile {
	display: flex;
	height: 40vh;
	margin-left: 7vw;
	margin-right: 7vw;
	margin-bottom: 50px;
}

.Carousel-Item {
	margin-left: 30px;
}

.Carousel-Item > img {
	height: 40vh;
}

.Carousel-Item > iframe {
	height: 40vh;
	width: 40vw;
}

.Portfolio-Item-Title {
	font-size: 3rem;
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
	word-break: break-word;
}

.Portfolio-Item-Title > h1 {
	margin-left: 10vw;
	margin-right: 10vw;
	font-size: 4rem;
  	text-align: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	color: #A6CE28;
}

.Portfolio-Item-Description {
	margin-left: 20vw;
	margin-right: 20vw;
}

.Portfolio-Item-Description-Text {
	white-space: pre-wrap;
	color: #dadfe1;
	word-break: break-word;
}

.Portfolio-Item-Title-Mobile {
	padding-top: 10vh;
}