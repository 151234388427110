.Projects-Outter {
	padding-left: 10vw;
	padding-right: 10vw;
}

.Projects-Title {
	display: flex;
	justify-content: center;
}

.Projects-Title > h1 {
	font-size: 4.5rem;
	color: #a6ce28;
	text-align: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.Featured-Outter {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
}

.Project {
	max-width: 22rem;
	margin-left: 20px;
	margin-right: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Project > p {
	font-size: 1.5rem;
	color: #dadfe1;
	text-align: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	margin-top: 5px;
	margin-left: 20px;
	margin-right: 20px;
}

.Project:hover {
	background-color: #6b6e70;
	filter: brightness(0.8);
	border-radius: 5px;
}

.Project > img {
	height: 11rem;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(35, 39, 42, 1);
	border-radius: 10px;
	margin: 50px;
	margin-top: 40px;
	margin-bottom: 0px;
}

.Project-Mobile {
	max-width: 22rem;
	margin-left: 20px;
	margin-right: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Project-Mobile > p {
	font-size: 1.5rem;
	color: #dadfe1;
	text-align: center;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	margin-top: 5px;
	margin-left: 20px;
	margin-right: 20px;
}

.Project-Mobile:hover {
	background-color: #6b6e70;
	filter: brightness(0.8);
	border-radius: 5px;
}

.Project-Mobile > img {
	height: 9rem;
	border-width: 5px;
	border-style: solid;
	border-color: rgba(35, 39, 42, 1);
	border-radius: 10px;
	margin: 50px;
	margin-top: 40px;
	margin-bottom: 0px;
}

.Portfolio-Link-Real-Outer {
	display: flex;
	justify-content: center;
}

.Portfolio-Link-Outter {
	margin-top: 10px;
}

.Portfolio-Link-Outter:hover {
	background-color: #6b6e70;
	filter: brightness(0.8);
	border-radius: 5px;
}

.Portfolio-Link-Outter-Mobile {
	margin-top: 10px;
}

.Portfolio-Link-Outter-Mobile:hover {
	background-color: #6b6e70;
	filter: brightness(0.8);
	border-radius: 5px;
}

.Portfolio-Link {
	margin-top: 30px;
	display: inline-block;
	text-decoration: none;
	color: #43b0ef;
	font-size: 1.5rem;
}

.Portfolio-Link-Mobile {
	display: inline-block;
	color: #43b0ef;
	font-size: 1.5rem;
}

.Portfolio-Link-Outter-Image {
	height: 2rem;
	vertical-align: bottom;
	margin-right: 10px;
}
