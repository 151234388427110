:root {
  --header-top-colour: rgba(35, 39, 42, 1);
  --header-bottom-colour: rgba(35, 39, 42, 0.75);
  --header-transition-speed: 0.65s;
  --lower-background: #474b4f;
  --lower-shadow: rgba(35, 39, 42, 0.8);
  --top-opacity: 1;
  --bottom-opacity: 1;
}

h1, h2, h3, h3, p {
  padding: 0px;
  margin: 0px;
}

.Home {
  width: 100%;
  min-height: 100vh;
  background: #6b6e70;
}

.Header {
  height: 10vh;
  margin-bottom: 10vh;
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Header-Mobile {
  height: 30vh;
  padding-top: 10vh;
  display: flex;border-width: 1px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.HeaderTopFirstLoad {
  background-color: var(--header-top-colour);
}

.HeaderTop {
    -webkit-animation: LightFadeIn var(--header-transition-speed);
       -moz-animation: LightFadeIn var(--header-transition-speed);
        -ms-animation: LightFadeIn var(--header-transition-speed);
         -o-animation: LightFadeIn var(--header-transition-speed);
            animation: LightFadeIn var(--header-transition-speed);
  background-color: var(--header-top-colour);
}

.HeaderScrolled {
    -webkit-animation: DarkFadeIn var(--header-transition-speed);
       -moz-animation: DarkFadeIn var(--header-transition-speed);
        -ms-animation: DarkFadeIn var(--header-transition-speed);
         -o-animation: DarkFadeIn var(--header-transition-speed);
            animation: DarkFadeIn var(--header-transition-speed);
  background-color: var(--header-bottom-colour);
}

@keyframes DarkFadeIn {
    from { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
    to   { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
}
@-moz-keyframes DarkFadeIn {
    from { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
    to   { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
}
@-webkit-keyframes DarkFadeIn {
    from { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
    to   { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
}
@-ms-keyframes DarkFadeIn {
    from { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
    to   { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
}
@-o-keyframes DarkFadeIn {
    from { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
    to   { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
}

@keyframes LightFadeIn {
    from { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
    to   { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
}
@-moz-keyframes LightFadeIn {
    from { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
    to   { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
}
@-webkit-keyframes LightFadeIn {
    from { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
    to   { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
}
@-ms-keyframes LightFadeIn {
    from { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
    to   { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
}
@-o-keyframes LightFadeIn {
    from { opacity: var(--bottom-opacity); background-color: var(--header-bottom-colour); }
    to   { opacity: var(--top-opacity); background-color: var(--header-top-colour); }
}

.Heading-Link {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.Heading-Link-Mobile {
  font-size: 1.2rem;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.Heading-Link:Hover {
  background-color: #61892F;
}

.Heading {
  margin-left: 8vw;
  margin-right: 8vw;
  color: white;
}

.Logos {
  display: flex;
  background-color: var(--header-top-colour);
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  height: 5vh;
  padding-right: 20px;
  padding-bottom: 0px;
}

.LogoLink {
  display: flex;
  padding: 0px;
  margin: 0px;
}

.LogoLink:hover {
  filter: brightness(0.6);
}

.LogoLink > img {
  height: 4vh;
  margin-right: 15px;
  margin-bottom: 0px;
}

.Title {
  height: 85vh;
  background-color: var(--header-top-colour);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Title-Mobile {
  height: 55vh;
  background-color: var(--header-top-colour);
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.Title-Image {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
  margin-left: 100px;
}

.Title-Image > img {
  width: 40rem;
  margin-right: 100px;
}

.Title-Text {
  flex: 0.5;
  font-size: 2.5rem;
  color: white;
}

.Title-Text > p {
  display: inline;
}

.Title-Text-Mobile {
  margin-left: 25px;
  font-size: 1.6rem;
  color: white;
}

.Title-Text-Mobile > p {
  display: inline;
}


.Title-Text-Yellow {
  color: #E7C855;
}

.Title-Text-Green {
  color: #A6CE28;
}

.Title-Text-Blue {
  color: #43B0EF;
}


.Title-Blinking-Cursor { 
  position: relative;
  display: inline-block;
  font-family: Monaco, Arial, Helvetica, sans-serif;
  line-height: 24px;
  min-width: 360px;
  font-weight: 100;
  font-size: 2.5rem;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

.Title-Blinking-Cursor::after {
  content: "";
  margin-top: 10px;
  display: inline-block;
  background-color: #606060;
  vertical-align: top;
  width: 15px;
  height: 2.5rem;
  -webkit-animation: blink 1.5s step-end infinite;
  animation: blink 1s step-end infinite;
}

.Title-Blinking-Cursor-Mobile {
  position: relative;
  display: inline-block;
  font-family: Monaco, Arial, Helvetica, sans-serif;
  line-height: 24px;
  min-width: 360px;
  font-weight: 100;
  font-size: 2.5rem;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

.Title-Blinking-Cursor-Mobile::after {
  content: "";
  margin-top: 8px;
  display: inline-block;
  background-color: #606060;
  vertical-align: top;
  width: 10px;
  height: 1.6rem;
  -webkit-animation: blink 1.5s step-end infinite;
  animation: blink 1s step-end infinite;
}

@-webkit-keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
@keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
@-moz-keyframes blink {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}@-ms-keyframes "blink" {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
@-o-keyframes "blink" {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

.Mobile-Hamburger {
  position: fixed;
  z-index: 999;
  right: 0px;
  margin-top: 15px;
  padding: 5px;
  padding-top: 10px;
  padding-right: 15px;
  background-color: rgba(35, 39, 42, 0.9);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.Mobile-Hamburger > img {
  width: 2rem;
}

.Heading-Link-Mobile-Menu {
  text-decoration: none;
  align-items: flex-end;
  color: #43B0EF;
  padding: 5px;
  font-size: 1.2rem;
}

.Mobile-Menu {
  position: fixed;
  z-index: 999;
  right: 0px;
  margin-top: 15px;
  margin-right: calc(10vw + 30px);
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: rgba(35, 39, 42, 0.9);
}