.AboutOutter {
	padding-left: 22vw;
	padding-right: 22vw;
	background-color: transparent;
	padding-top: 20vh;
}

.AboutOutter-Mobile {
	padding-left: 5vw;
	padding-right: 5vw;
	background-color: transparent;
	padding-top: 10vh;
}

.About-Top{
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.About-Top-Mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.About-Image {
	display: flex;
}

.About-Image-Img {
	height: 20vh;
	max-height: 300px;
	border-radius: 360px;
	margin-right: 50px;
}

.About-Image-Img-Mobile {
	height: 60vw;
	max-height: 300px;
	border-radius: 360px;
}

.About-Title {
}

.About-Title > h1 {
	font-size: 4.5rem;
	color: #A6CE28;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.About-Title > h3 {
	font-size: 2rem;
	color: #E7C855;
}

.About-Title-Mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.About-Title-Mobile > h1 {
	font-size: 4.5rem;
	color: #A6CE28;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
	text-align: center;
}

.About-Title-Mobile > h3 {
	font-size: 2rem;
	color: #E7C855;
}

.AboutDesc {
	margin-top: 10px;
	margin-left: 150px;
	margin-right: 150px;
	text-align: center;
	color: #dadfe1;
}

.AboutDesc-Mobile {
	margin-top: 10px;
	margin-left: 10px;
	margin-right: 10px;
	text-align: center;
	color: #dadfe1;
}

.AboutLinks {
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	justify-content: center;
	flex-wrap: wrap;
}

.AboutLink {
	display: inline-flex;
	align-items: center;
	color: #43B0EF;
	text-decoration: none;
	padding-right: 20px;
}

.AboutLink > img {
	margin-top: 5px;
	margin-bottom: 5px;
	margin-left: 20px;
	margin-right: 5px;
	height: 2rem;
}

.AboutLink:hover {
  	background-color: #6b6e70; 
  	filter: brightness(0.8);
	border-radius: 5px;
}

.AbolutLink > img:hover {
	background-color: red;
}